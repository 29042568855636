import { useEffect, lazy, Suspense } from 'react';

import { Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";

import { useTranslation } from "react-i18next";

import 'react-tippy/dist/tippy.css'

import req from '../func/req';

import centrifuge from '../func/centrifuge';

import "moment/locale/ru";

import Modal from '../components/Modals/Modal';

const Container = lazy(() => import('../pages/Container'));

const App = () => {
  const { t } = useTranslation();


  useEffect(() => {

    req({
      url: "/common/socket"
    })
      .then((token) => {
        centrifuge.setToken(token);
        centrifuge.connect();
      })
      .catch(ex => {
        console.log(ex);
      })

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "ab8iurge",
      hide_default_launcher: true
    });
  }, [])

  return <>
    <Modal />
    <Routes>
      <Route path='*' element={<Container />} />
    </Routes>
  </>
}

export default App;
