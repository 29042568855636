import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        type: localStorage.getItem("settings:type") || "casino",
        modal: null,
        modalClosing: false,
        traker: null
    },
    reducers: {
        modalOpen: (state, action) => {
            if(action.key) state.traker = action.key
            state.modal = action.payload;
        },
        modalClosingOn: (state) => {
            state.modalClosing = true;
        },
        supportSwitch: (state) => {
            state.support = !state.support;
        },
        modalClosingOff: (state) => {
            state.modalClosing = false;
            state.modal = null;
        },
    },
});

export const { switchType, modalOpen, modalClosingOn, modalClosingOff } = settingsSlice.actions;
export default settingsSlice.reducer;