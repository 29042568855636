import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import req from '../../func/req';

import setStyle from '../../func/style'

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async () => {
    try {
      if(!localStorage.getItem("token"))
        return null;
      const account = await req({
        url: "/account"
      });
      if(account.colors) setStyle(account.colors)
      return account;
    } catch (ex) {
      return false;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    entity: null,
    status: 'idle',
    error: null
  },
  reducers: {
    update: (state, action) => {
      state.entity = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.entity = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { update } = userSlice.actions;
export default userSlice.reducer;