import axios from 'axios';
import Cookies from "js-cookie";
import { isMobile } from 'react-device-detect';

import { toast } from 'react-toastify';

let fingerprint;

// getFingerprint()
//     .then((fp) => {
//         fingerprint = fp;
//     })

function getBrowserName(userAgent) {
    if (userAgent.indexOf("Firefox") > -1) {
      return "Firefox";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      return "Samsung Internet";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      return "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      return "Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      return "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      return "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      return "Safari";
    } else {
      return "Unknown";
    }
}

function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const referrer = document.referrer;
    const browser = getBrowserName(userAgent);
    return { userAgent, platform, referrer, browser };
  }

const req = (config) => {
    return new Promise((resolve, reject) => {

        const { userAgent, platform, referrer, browser } = getBrowserInfo();

        const token = localStorage.getItem('token');
        config.baseURL = process.env.NODE_ENV === "development" ? (window.location.hostname !== "localhost" ? "https://vdk.ngrok.io" : "http://localhost:5000") : ("https://api." + window.location.hostname)
        if (!config.headers) config.headers = {}
        if (token) config.headers['x-auth-key'] = token;
        config.headers['Accept-Language'] = (window.i18n.language.length === 2 || window.i18n.language === "bydlo" ? window.i18n.language : window.i18n.language.slice(0, 2));
        config.headers['x-domain'] = process.env.NODE_ENV === "development" ? 'matchpay.com' : window.location.hostname;
        config.headers['x-device'] = isMobile ? "mobile" : "pc";
        config.headers['Platform'] = platform;
        config.headers['Device'] = isMobile ? "Mobile" : "Desktop";
        config.headers['Browser'] = browser;
        config.headers['Referrer'] = referrer;
        config.headers['extra-ref'] = Cookies.get("refferer");

        if(fingerprint){
            config.headers['x-visitor'] = fingerprint;
        }

        axios(config)
            .then(({ data }) => {
                resolve(data)
                if (config.toast)
                    toast.success(data.text || window.t('success'));
            })
            .catch((ex) => {
                if (ex && ex.response && ex.response.status === 401) {
                    localStorage.removeItem('token');
                }
                reject(ex);
                if (!config.hideError) {
                    if(ex && ex.response && ex.response.data) {

                        if(ex.response.data.ban) {
                            if (window.location.pathname !== '/banned') {
                                window.location.href = '/banned';
                            }

                            
                        } else if (ex.response.data.inrest) {
                            if (window.location.pathname !== '/rest') {
                                window.location.href = '/rest';
                            }
                        } else {
                            toast.error(ex && ex.response && ex.response.data && (ex.response.data.message || ex.response.data.error) ? (ex.response.data.message || ex.response.data.error) : window.t('requestError'));
                        }
                    }
                }
            })
    })
};

export default req;