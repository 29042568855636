import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/userSlice';
import settingsReducer from './User/settingsSlice';


export default configureStore({
  reducer: {
    user: userReducer,
    settings: settingsReducer
  },
});
