import './loading.css';
import { ReactComponent as LoadingIcon } from '../../img/i/loading.svg'


const Loading = () => {

    return <div className="loading">
        <LoadingIcon className='mainLoad' />
    </div>
}

export default Loading;