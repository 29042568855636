import { useEffect, useRef, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import './modals.css';
import './Auth/auth.css';

const modalComponents = {
    twofa: lazy(() => import('./Auth/Twofa')),
    education: lazy(() => import('./Auth/Education')),
    problem: lazy(() => import('./Problem/Problem')),
    attention: lazy(() => import('./Problem/Attention')),
};

const Modal = ({ w }) => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.settings.modal);
    const isModalClosing = useSelector(state => state.settings.modalClosing);
    const ref = useRef(null);
    const scrollPosition = useRef(0);

    useEffect(() => {
        if (ref.current) {
            if (!isModalClosing) {
                disableBodyScroll(ref.current);
                scrollPosition.current = window.scrollY;
            } else {
                enableBodyScroll(ref.current);
                window.scrollTo(0, scrollPosition.current);
            }
        }

        return () => {
            clearAllBodyScrollLocks();
        };
    }, [isModalClosing, ref, modal]);

    const handleClickAway = () => {
        if(modal === 'education') return
        dispatch({ type: "settings/modalClosingOn" });
        setTimeout(() => {
            dispatch({ type: "settings/modalClosingOff" });
        }, 300);
    };

    const ModalContent = modal && modalComponents[modal];

    return (
        modal && (
            <div className={`modalWrapper ${isModalClosing ? "disappear" : ""}`} ref={ref}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div className={`modal`}>
                        <Suspense>
                            {ModalContent && <ModalContent scrollParentRef={ref} />}
                        </Suspense>
                    </div>
                </ClickAwayListener>
            </div>
        )
    );
};

export default Modal;
