import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import i18n from './app/i18n';
import App from './app/App';
import store from './app/store';

import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import Loading from './pages/Loading/Loading';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css'
import 'animate.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <I18nextProvider i18n={i18n} defaultNS={'common'}>
          <Provider store={store}>
              <Router>
                <App />
                <ToastContainer />
              </Router>
          </Provider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>
);