const setCSS = (colors) => {
    const root = document.documentElement;

    // Установка CSS-переменных на основе данных с backend
    root.style.setProperty('--back', colors.back || '#0C111C');
    root.style.setProperty('--backOpacity', colors.back ? colors.back + 'CC' : '#0C111CCC');
    root.style.setProperty('--text', colors.text || '#FFFFFF');
    root.style.setProperty('--textSecond', colors.textSecond || '#475774');
    root.style.setProperty('--block', colors.block || '#192232');
    root.style.setProperty('--accent', colors.accent || '#0090FF');
};
  
export default setCSS;
  